import { colours } from "../../styles/colours";
import styled from "styled-components";
import AuthSync from "../AuthSync";
import { AppBar } from "../layout/AppBar";
import ScrollTop from "../ScrollTop";

const Root = styled.div`
  background-color: ${colours.grey[1]};
  min-height: 100%;
  color: ${colours.primary[9]};
  font-weight: 500;
  padding-bottom: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Layout = props => {
  const { children, ...dataProps } = props;
  return (
    <>
      <AuthSync {...dataProps} />
      <ScrollTop />
      <Root>
        <AppBar {...dataProps} />
        {children}
      </Root>
    </>
  );
};

export default Layout;
