const isInAnnotationColumn = node =>
  node?.className?.includes("annotation-location");

const lineNumber = node =>
  node.className?.startsWith("rouge-line") && node.className.match(/(\d+)/)[0];

const getLineInfo = node => {
  let selectedFromAnnotationColumn = false;
  while (node != null && !lineNumber(node)) {
    selectedFromAnnotationColumn =
      selectedFromAnnotationColumn || isInAnnotationColumn(node);
    node = node.parentNode;
  }
  if (node == null) {
    return { lineNumber: null };
  } else {
    return {
      lineNumber: parseInt(lineNumber(node)),
      selectedFromAnnotationColumn
    };
  }
};

const ignoreHighlight = ({ startLine, endLine, startedFromAnnotation }) => {
  const isOneLine = startLine === endLine;
  const isFromSingleClickOnAnnotationColumn =
    isOneLine && startedFromAnnotation;
  const hasNullLines = !startLine || !endLine;
  return hasNullLines || isFromSingleClickOnAnnotationColumn;
};

export const getHighlightedLines = () => {
  let selection;
  if (window.getSelection) {
    selection = window.getSelection();
  } else if (document.selection) {
    selection = document.selection.createRange();
  }
  const {
    lineNumber: startLine,
    selectedFromAnnotationColumn: startedFromAnnotation
  } = getLineInfo(selection.anchorNode);
  const { lineNumber: endLine } = getLineInfo(selection.focusNode);
  if (ignoreHighlight({ startLine, endLine, startedFromAnnotation }))
    return null;

  if (startLine < endLine) {
    return { startLine, endLine };
  } else {
    return {
      startLine: endLine,
      endLine: startLine
    };
  }
};

export const clearAnySelectedLines = () => {
  const selected = getHighlightedLines();
  if (!selected) return null;
  if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty(); // Chrome
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges(); // Firefox
    }
  } else if (document.selection) {
    document.selection.empty(); // IE
  }
};
