import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import apiClient from "../apiClient";

const AuthSync = ({ setUser }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.startsWith("/auth")) return;
    apiClient.currentUser(({ user }) => setUser(user || null));
  }, [location, setUser]);
  return null;
};

export default AuthSync;
