import styled from "styled-components";
import { colours } from "../../styles/colours";

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color: white;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  & + & {
    margin-left: 1rem;
  }
`;

export const PrimaryButton = styled(Button)`
  color: ${colours.primary[5]};
  border: solid 1px ${colours.primary[5]};
  &:hover {
    color: ${colours.primary[7]};
    border-color: ${colours.primary[7]};
  }
`;

export const SecondaryButton = styled(Button)`
  color: ${colours.grey[5]};
  padding: 0.5rem;
  &:hover {
    color: ${colours.grey[7]};
  }
`;
