import { FullPathLinks, Section } from "./repoLayout/FullPathLinks";
import { colours } from "../../styles/colours";
import styled from "styled-components";
import { spacing } from "../../styles/spacing";
import { SearchButton } from "./directory/SearchButton";
import { FilesList } from "./FilesList";
import { ContentCard } from "./styles";
import { SmallPageWidth } from "../layout/styles";

const Root = styled(SmallPageWidth)``;

const PathSection = styled.div`
  padding-bottom: ${spacing.default};
  padding-left: ${spacing[2]};
  padding-top: ${spacing[1]};
  border-bottom: solid 1px ${colours.grey[1]};
`;

const ButtonSection = styled(Section)`
  margin-left: auto;
  margin-right: ${spacing[1.5]};
`;

export const Directory = ({ directory, repository, tabs }) => {
  return (
    <Root>
    {tabs}
      <ContentCard>
        <PathSection>
          <FullPathLinks {...{ repository }}>
            <ButtonSection>
              <SearchButton {...{ repository }} />
            </ButtonSection>
          </FullPathLinks>
        </PathSection>
        <FilesList files={directory} />
      </ContentCard>
    </Root>
  );
};
