import styled, { css } from "styled-components";

const pageWidthStyles = css`
  max-width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  @media screen and (max-width: 540px) {
    padding: 0 1rem;
  }
  @media screen and (max-width: 420px) {
    padding: 0 0.5rem;
  }
`;

export const SmallPageWidth = styled.div`
  ${pageWidthStyles}
  width: 600px;
`;

export const MediumPageWidth = styled.div`
  ${pageWidthStyles}
  width: 900px;
`;

export const WidePageWidth = styled.div`
  ${pageWidthStyles}
  width: 1440px;
`;
