export const colours = {
  grey: {
    1: "hsl(210, 30%, 96%)",
    2: "hsl(210, 50%, 95%)",
    3: "hsl(210, 40%, 90%)",
    4: "hsl(210, 35%, 85%)",
    5: "hsl(210, 30%, 73%)",
    6: "hsl(210, 20%, 60%)",
    7: "hsl(210, 15%, 48%)",
    8: "hsl(210, 15%, 35%)",
    9: "hsl(210, 15%, 25%)"
  },
  primary: {
    1: "hsl(206, 78%, 98%)",
    2: "hsl(206, 78%, 94%)",
    3: "hsl(206, 80%, 80%)",
    4: "hsl(206, 88%, 74%)",
    5: "hsl(206, 81%, 57%)",
    7: "hsl(206, 75%, 45%)",
    8: "hsl(204, 75%, 35%)",
    9: "hsl(204, 61%, 26%)"
  },
  positive: {
    1: "hsl(120, 100%, 95%)",
    2: "hsl(120, 100%, 90%)",
    3: "hsl(120, 80%, 80%)",
    5: "hsl(120, 90%, 45%)",
    6: "hsl(120, 80%, 40%)",
    7: "hsl(120, 85%, 35%)",
    8: "hsl(120, 90%, 30%)",
    9: "hsl(120, 100%, 25%)"
  },
  negative: {
    1: "hsl(0, 100%, 98%)",
    2: "hsl(0, 80%, 94%)",
    3: "hsl(0, 80%, 80%)",
    5: "hsl(0, 90%, 45%)",
    6: "hsl(0, 85%, 40%)",
    7: "hsl(0, 80%, 35%)",
    8: "hsl(0, 88%, 28%)",
    9: "hsl(0, 90%, 20%)"
  },
  warning: {
    1: "hsl(44, 100%, 95%)",
    2: "hsl(44, 80%, 90%)",
    3: "hsl(44, 90%, 65%)",
    5: "hsl(44, 97%, 54%)",
    7: "hsl(44, 90%, 48%)",
    8: "hsl(44, 80%, 42%)",
    9: "hsl(44, 80%, 30%)"
  },
  white: "hsl(0, 0%, 100%)"
};

export const annotationColours = {
  error: {
    border: colours.negative[3],
    selectedBorder: colours.negative[8],
    background: colours.negative[2],
  },
  warning: {
    border: colours.warning[3],
    selectedBorder: colours.warning[8],
    background: colours.warning[1]
  },
  info: {
    border: colours.primary[3],
    selectedBorder: colours.primary[7],
    background: colours.primary[2]
  },
  initial: {
    border: colours.grey[3],
    selectedBorder: colours.grey[7],
    background: colours.grey[2]
  }
};
