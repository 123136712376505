import styled from "styled-components";

const Root = styled.div`
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  display: contents;
  font-size: 1rem;
  line-height: 1.5;

  ${({ highlightCss }) => highlightCss}
  .rouge-line {
    white-space: pre-wrap;
    word-break: break-word;
    border-top: solid transparent 1px;
  }
`;

export const DisplayServerHighlightedContent = ({ highlightedCode }) => {
  return (
    <Root
      highlightCss={highlightedCode.css}
      dangerouslySetInnerHTML={{ __html: highlightedCode.code }}
    />
  );
};
