import { FullPathLinks } from "../repoLayout/FullPathLinks";
import { annotationColours, colours } from "../../../styles/colours";
import styled from "styled-components";
import { spacing } from "../../../styles/spacing";
import { DisplayServerHighlightedContent } from "./DisplayServerHighlightedContent";
import { Annotation } from "../annotations/Annotation";
import { useAnnotations } from "../clientHighlighting/codeRenderer/useAnnotations";
import { WidePageWidth } from "../../layout/styles";
import { LineNumber } from "../code/style";
import { useEffect } from "react";

const Root = styled(WidePageWidth)``;

const PathSection = styled.div`
  padding-bottom: ${spacing.default};
  padding-left: ${spacing[2]};
  padding-top: ${spacing[1]};
  border-bottom: solid 1px ${colours.grey[1]};
`;

const Card = styled.div`
  background-color: ${colours.white};
  margin: ${spacing[2]} 0;
  border-radius: ${spacing.default};
  overflow: hidden;
  box-shadow: 0.1rem 0.1rem 0.2rem ${colours.grey[3]},
    0.2rem 0.2rem 0.2rem ${colours.grey[1]};
`;

const AnnotatedContentRoot = styled.div`
  margin: 1rem;
  margin-right: 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${({ rows }) =>
      rows.length.toString().length * 0.75}rem 3fr 2fr;
  grid-template-rows: ${({ annotations, rows }) =>
    rows
      .map((_, index) =>
        annotations.some(a => a.endLine === index + 1) ? "auto" : "max-content"
      )
      .join(" ")};
`;

export const FileColumn = styled.div`
  padding: 1rem 0 !important;
  display: contents;
  * {
    grid-column: 2 / 3;
  }
  ${({ annotations }) => {
    return annotations
      .map(
        (annotation, index) =>
          `
            .rouge-line-${annotation.startLine} {
              border-top: solid 1px ${
                annotationColours[annotation.purpose].border
              };
            }
            ${[...Array(annotation.endLine - annotation.startLine + 1)]
              .map(
                (_, index) => `
              .rouge-line-${index + annotation.startLine} {
                background-color: ${
                  annotationColours[annotation.purpose].background
                };
              }
            `
              )
              .join("\n\n")}
          `
      )
      .join("\n\n");
  }}
`;

export const File = ({
  repository,
  annotations: initialAnnotations,
  highlightedCode,
  tabs,
  file
}) => {
  const totalLines = highlightedCode.code.split("rouge-line ").length - 1;
  const rows = [...new Array(totalLines)];
  const { removeAnnotation, updateAnnotation, annotations } = useAnnotations({
    initialAnnotations
  });

  useEffect(() => {
    const queryParamAnnotationId = new URLSearchParams(
      window.location.search
    ).get("annot");
    const annotationElement = document.querySelector(
      `[data-annotation-id="${queryParamAnnotationId}"]`
    );
    annotationElement?.scrollIntoView();
  }, []);

  return (
    <Root>
      {tabs}
      <Card>
        <PathSection>
          <FullPathLinks {...{ repository }} />
        </PathSection>
        <AnnotatedContentRoot id="file-wrapper" {...{ annotations, rows }}>
          {rows.map((_, index) => (
            <LineNumber key={index}>{index + 1}</LineNumber>
          ))}
          <FileColumn {...{ annotations }}>
            <DisplayServerHighlightedContent
              highlightedCode={highlightedCode}
            />
          </FileColumn>
          {annotations.map(annotation => (
            <Annotation
              key={annotation.id}
              {...{
                annotation,
                removeAnnotation,
                updateAnnotation,
                file,
                highlightedCode
              }}
            />
          ))}
        </AnnotatedContentRoot>
      </Card>
    </Root>
  );
};
