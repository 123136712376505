import { useEffect, useState } from "react";
import styled from "styled-components";
import apiClient from "../../../apiClient";
import { MediumPageWidth } from "../../layout/styles";
import { FeedAnnotation } from "./FeedAnnotation";

const Root = styled(MediumPageWidth)``;

export const Feed = ({ repository, tabs, codeCss }) => {
  const [annotations, setAnnotations] = useState();
  useEffect(() => {
    apiClient.getRepoAnnotations(repository.id).then(setAnnotations);
  }, [repository.id]);
  return (
    <Root>
      {tabs}
      <div>
        {!annotations && "loading"}
        {annotations?.map(annotation => (
          <FeedAnnotation key={annotation.id} {...{ codeCss, annotation }} />
        ))}
      </div>
    </Root>
  );
};
