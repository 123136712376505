import { Link } from "react-router-dom";
import { colours } from "../../styles/colours";
import styled from "styled-components";
import { spacing } from "../../styles/spacing";
import apiClient from "../../apiClient";

const Root = styled.div`
  padding: ${spacing[2]};
  display: flex;
  width: 100%;
  box-sizing: border-box;
`;

const RepoName = styled.div`
  margin-left: 1rem;
`;

const Actions = styled.div`
  margin-left: auto;
`;

const LogoLink = styled(Link)`
  color: ${colours.primary[9]};
`;

const AppBarButton = styled.button`
  background-color: ${colours.primary[5]};
`;

export const AppBar = ({
  user,
  setUser,
  isLoggedIn,
  isLoggedOut,
  currentRepo
}) => {
  return (
    <Root>
      <LogoLink to="/">SourceShine</LogoLink>
      <RepoName>{currentRepo}</RepoName>
      <Actions>
        {isLoggedOut && (
          <AppBarButton
            onClick={() => {
              setUser(undefined);
              apiClient.login();
            }}
          >
            Login
          </AppBarButton>
        )}
        {isLoggedIn && (
          <>
            {user.email}
            <AppBarButton onClick={() => apiClient.logout(() => setUser(null))}>
              Logout
            </AppBarButton>
          </>
        )}
      </Actions>
    </Root>
  );
};
