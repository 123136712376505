import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import apiClient from "../../../../apiClient";
import { MediumPageWidth } from "../../../layout/styles";

const Root = styled(MediumPageWidth)``;
const Content = styled.p`
  white-space: pre-wrap;
`;

export const Concept = () => {
  const [concept, setConcept] = useState();
  const {
    params: { conceptId }
  } = useRouteMatch();

  useEffect(() => {
    apiClient.getConcept(conceptId).then(setConcept);
  }, [conceptId]);

  if (!concept) {
    return "Loading...";
  }
  return (
    <Root>
      <h1>{concept.title}</h1>
      <Content>{concept.content}</Content>
    </Root>
  );
};
