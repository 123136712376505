import { annotationColours } from "../../../styles/colours";
import styled, { css } from "styled-components";
import { AnnotationEdit } from "./AnnotationEdit";
import { AnnotationDisplay } from "./AnnotationDisplay";

const Root = styled.div`
  grid-column: 3 / 4;
  position: relative;
  padding-bottom: 0.5rem;
  ${({ annotation }) => css`
    grid-row: ${annotation.startLine} / ${annotation.endLine + 1};
    border-top: solid 1px ${annotationColours[annotation.purpose].border};
  `}
`;

export const Annotation = ({
  annotation,
  removeAnnotation,
  updateAnnotation,
  file,
  highlightedCode
}) => {
  return (
    <Root annotation={annotation} data-annotation-id={annotation.id}>
      {annotation.status === "saved" && (
        <AnnotationDisplay
          {...{
            annotation,
            removeAnnotation,
            updateAnnotation
          }}
        />
      )}
      {annotation.status !== "saved" && (
        <AnnotationEdit
          {...{
            annotation,
            removeAnnotation,
            updateAnnotation,
            file,
            highlightedCode
          }}
        />
      )}
    </Root>
  );
};
