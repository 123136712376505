import { NavLink as ReactRouterNavLink } from "react-router-dom";
import { colours } from "../../../styles/colours";
import styled from "styled-components";

const Root = styled.div`
  border-bottom: solid 1px ${colours.grey[4]};
  display: flex;
`;

const NavLink = styled(ReactRouterNavLink)`
  color: ${colours.grey[8]};
  padding: 1rem 2rem;
  text-decoration: none;
  box-sizing: border-box;
  border-bottom: solid 3px transparent;
  &.active {
    border-bottom: solid 3px ${colours.primary[5]};
  }
`;

export const RepoNavTabs = ({ repository }) => (
  <Root>
    <NavLink
      isActive={(match, loc) => {
        const isFeed =
          loc.pathname.endsWith("feed") && loc.pathname.split("/").length === 4;
        return !isFeed;
      }}
      to={`/r/${repository.id}`}
    >
      Code
    </NavLink>
    <NavLink to={`/r/${repository.id}/feed`}>Feed</NavLink>
  </Root>
);
