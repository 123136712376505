import { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import apiClient from "../../apiClient";
import { Directory } from "./Directory";
import { Search } from "./search/Search";
import { File } from "./file/File";
import Layout from "../layout/Layout";
import { RepoNavTabs } from "./repoLayout/RepoNavTabs";
import { Feed } from "./feed";
import { Concept } from "./concepts/concept";
import { NewConcept } from "./concepts/newConcept";

const DirectoryPage = ({ userProps }) => {
  const [data, setData] = useState();
  const {
    params: { view, directoryPath, repoId }
  } = useRouteMatch();

  useEffect(() => {
    apiClient.getRepoDirectory(repoId, directoryPath).then(setData);
  }, [repoId, directoryPath]);

  let Component;
  if (view === "search") {
    Component = Search;
  } else if (view === "feed") {
    Component = Feed;
  } else if (data?.file) {
    Component = File;
  } else {
    Component = Directory;
  }

  return (
    <Layout currentRepo={data?.repository.repo} {...userProps}>
      <Switch>
        <Route exact path="/r/:repoId/concepts/new">
          <NewConcept />
        </Route>
        <Route exact path="/r/:repoId/concepts/:conceptId">
          <Concept />
        </Route>
        <Route exact path="/r/:repoId/:view?/:head?/:directoryPath*">
          {data && (
            <Component
              {...{ ...data }}
              tabs={<RepoNavTabs {...{ ...data }} />}
            />
          )}
        </Route>
      </Switch>
    </Layout>
  );
};

export default DirectoryPage;
