import { Link } from "../shared/Link";
import { colours } from "../../styles/colours";
import styled from "styled-components";
import { spacing } from "../../styles/spacing";
import folderIcon from "../icons/folderIcon.svg";
import fileIcon from "../icons/fileIcon.svg";
import { useRouteMatch } from "react-router-dom";

const Root = styled.div`
  padding: ${spacing[0.5]} ${spacing.default};
`;

const FilesRowBorder = styled.div`
  width: 100%;
  padding: ${spacing[0.5]} ${spacing[0.5]};
  display: flex;
  align-items: center;
`;

const FilesRowContent = styled.div`
  width: 100%;
  padding: 0 ${spacing[0.5]};
  box-sizing: border-box;
`;

const FileRow = styled.div`
  border-radius: ${spacing[0.5]};
  overflow: hidden;
  margin-top: -1px; /* I think this was to cover something but 🤷‍♂️ */
  & + & ${FilesRowBorder} {
    border-top: solid 1px ${colours.grey[1]};
  }
  &:hover {
    color: ${colours.primary[5]};
    background-color: ${colours.primary[1]};
    ${FilesRowBorder} {
      border-color: ${colours.primary[1]};
    }
  }
  &:hover + & {
    ${FilesRowBorder} {
      border-color: ${colours.primary[1]};
    }
  }
`;

const Icon = styled.img`
  width: 25px;
  margin-right: 20px;
`;

export const FilesList = ({ files }) => {
  const {
    params: { repoId }
  } = useRouteMatch();
  return (
    <Root>
      {files.slice(0, 300).map(item => {
        const name = item.name || item.path;
        return (
          <FileRow key={name}>
            <FilesRowContent>
              <FilesRowBorder>
                {item.type === "dir" || item.type === "tree" ? (
                  <Icon src={folderIcon} />
                ) : (
                  <Icon src={fileIcon} />
                )}
                <div>
                  <Link to={`/r/${repoId}/files/head/${item.path}`}>
                    {name}
                  </Link>
                </div>
              </FilesRowBorder>
            </FilesRowContent>
          </FileRow>
        );
      })}
    </Root>
  );
};
