import { useCallback, useEffect, useMemo, useState } from "react";
import { getHighlightedLines } from "./getHighlightedLines";
import { nanoid } from "nanoid";

function lineRange({ startLine, endLine }) {
  var result = [startLine],
    nextNumber = startLine;
  while (nextNumber < endLine) {
    result.push((nextNumber += 1));
  }
  return result;
}

export const useAnnotations = ({ initialAnnotations }) => {
  const [annotations, setAnnotations] = useState(
    initialAnnotations?.map(a => ({ ...a, status: "saved" })) || []
  );
  const [isMouseDown, setisMouseDown] = useState(false);

  const setAnyPendingToInitial = useCallback(() => {
    if (!annotations.some(a => a.status === "pending")) return;

    const newAnnotations = annotations.map(annotation => ({
      ...annotation,
      status: annotation.status === "pending" ? "initial" : annotation.status,
      purpose: annotation.status === "pending" ? "info" : annotation.purpose
    }));
    setAnnotations(newAnnotations);
  }, [annotations]);

  const addAnnotation = useCallback(
    lines => {
      const sameInitialExists = annotations.find(
        annotation =>
          annotation.status === "initial" &&
          annotation.startLine === lines.startLine &&
          annotation.endLine === lines.endLine
      );
      if (sameInitialExists) return;
      const notInitialAnnotations = annotations.filter(
        a => a.status !== "initial" && a.status !== "pending"
      );
      const newRange = lineRange(lines);
      const anyOverlap = notInitialAnnotations.find(annotation => {
        const existingRange = lineRange(annotation);
        return existingRange.find(lineNumber => newRange.includes(lineNumber));
      });
      if (anyOverlap) {
        return;
      }
      setAnnotations([
        ...notInitialAnnotations,
        {
          ...lines,
          id: nanoid(),
          purpose: isMouseDown ? "initial" : "info",
          status: isMouseDown ? "pending" : "initial"
        }
      ]);
    },
    [annotations, isMouseDown]
  );

  const removeAnnotation = useCallback(
    id => {
      const remainingAnnotations = annotations.filter(a => a.id !== id);
      setAnnotations(remainingAnnotations);
    },
    [annotations]
  );

  const updateAnnotation = useCallback(
    annotation => {
      const otherAnnotations = annotations.filter(a => a.id !== annotation.id);
      setAnnotations([...otherAnnotations, annotation]);
    },
    [annotations]
  );

  const addAnnotationForHighlights = useCallback(() => {
    const selectedLines = getHighlightedLines();
    if (selectedLines) addAnnotation(selectedLines);
  }, [addAnnotation]);

  const [selectionCheckIntervals, setSelectionCheckIntervals] = useState([]);
  useEffect(() => {
    if (!isMouseDown) {
      setAnyPendingToInitial();
    }
    selectionCheckIntervals.map(i => clearInterval(i));
    if (isMouseDown) {
      let count = 0;
      const interval = setInterval(() => {
        addAnnotationForHighlights(isMouseDown);
        count++;
        if (count > 50 || !isMouseDown) {
          clearInterval(interval);
          setAnyPendingToInitial();
        }
      }, 200);
      setSelectionCheckIntervals(current => [...current, interval]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMouseDown, addAnnotationForHighlights]);

  useEffect(() => {
    const onPressStart = () => setisMouseDown(true);
    const onPressEnd = () => {
      setisMouseDown(false);
      addAnnotationForHighlights();
    };

    window.onmousedown = onPressStart;
    window.ontouchstart = () => {
      onPressStart();
      setTimeout(onPressEnd, 200);
    };

    window.onmouseup = onPressEnd;
    window.ontouchend = onPressEnd;
  }, [addAnnotationForHighlights]);

  const returnValues = useMemo(
    () => ({
      addAnnotation: () => {},
      removeAnnotation,
      updateAnnotation,
      annotations
    }),
    [removeAnnotation, updateAnnotation, annotations]
  );

  return returnValues;
};
