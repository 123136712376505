import Fuse from "fuse.js";

export const mapFilesForSearch = tree => {
  return tree.map(item => {
    const reversedSegments = item.path.split("/").reverse();
    const isFile = item.type === "blob";
    if (isFile) {
      reversedSegments[0] = reversedSegments[0] + reversedSegments[1];
    } else {
      reversedSegments.unshift(null);
    }
    return {
      ...item,
      reversedPathSegments: { ...reversedSegments }
    };
  });
};

export const filterTree = (tree, searchTerm) => {
  const searchResults = new Fuse(tree, {
    includeScore: true,
    keys: [
      { name: "reversedPathSegments.0", weight: 1 },
      { name: "reversedPathSegments.1", weight: 0.5 },
      { name: "reversedPathSegments.2", weight: 0.4 },
      { name: "reversedPathSegments.3", weight: 0.2 },
      { name: "reversedPathSegments.4", weight: 0.1 },
      { name: "path", weight: 0.2 }
    ]
  }).search(searchTerm);
  return searchResults.map(({ item }) => item);
};
