import styled from "styled-components";
import { annotationColours, colours } from "../../../styles/colours";
import { DisplayServerHighlightedContent } from "../file/DisplayServerHighlightedContent";
import { PathLink } from "../repoLayout/FullPathLinks";
import TimeAgo from "react-timeago";
import { LineNumber } from "../code/style";

const Root = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  background-color: ${colours.white};
  border-radius: 0.5rem;
  border-left: 3px solid
    ${({ annotation }) => annotationColours[annotation.purpose].border};
  border-bottom: 3px solid
    ${({ annotation }) => annotationColours[annotation.purpose].border};
`;

const UserAvatar = styled.img`
  width: 40px;
  border-radius: 50%;
  box-shadow: 0.1rem 0.1rem 0.2rem ${colours.grey[4]},
    0.2rem 0.2rem 0.2rem ${colours.grey[2]};
`;

const UserSection = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

const UserInfoSection = styled.div`
  margin-left: 1rem;
`;
const Username = styled.div``;
const PostedAt = styled.div`
  margin-top: 4px;
  color: ${colours.grey[5]};
`;

const PathSection = styled.div`
  margin-bottom: 1rem;
`;

const CommentSection = styled.div`
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid ${colours.grey[3]};
`;

const CodeSection = styled.div`
  max-height: 180px;
  overflow-y: auto;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${({ annotation }) =>
      annotation.endLine.toString().length * 0.75}rem 1fr;
`;

export const FileColumn = styled.div`
  display: contents;
  * {
    grid-column: 2 / 3;
  }
`;

export const FeedAnnotation = ({ annotation, codeCss }) => {
  const lineNumbers = [
    ...new Array(annotation.endLine - annotation.startLine + 1)
  ].map((_, index) => annotation.startLine + index);
  return (
    <Root annotation={annotation}>
      <UserSection>
        <UserAvatar src={annotation.createdBy.avatar} />
        <UserInfoSection>
          <Username>{annotation.createdBy.username}</Username>
          <PostedAt>
            <TimeAgo date={annotation.createdAt} />
          </PostedAt>
        </UserInfoSection>
      </UserSection>
      <CommentSection>{annotation.text}</CommentSection>
      <PathSection>
        <PathLink
          to={`/r/${annotation.sourceFile.repositoryId}/files/head/${annotation.sourceFile.path}?annot=${annotation.id}`}
        >
          {annotation.sourceFile.path}
        </PathLink>
      </PathSection>

      <CodeSection {...{ annotation }}>
        {lineNumbers.map(number => (
          <LineNumber key={number}>{number}</LineNumber>
        ))}
        <FileColumn>
          <DisplayServerHighlightedContent
            highlightedCode={{
              code: annotation.highlightedChunk,
              css: codeCss
            }}
          />
        </FileColumn>
      </CodeSection>
    </Root>
  );
};
