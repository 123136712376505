import { annotationColours, colours } from "../../../styles/colours";
import styled, { css } from "styled-components";

const iconForType = {
  error: "!",
  warning: "?",
  info: "i",
  initial: "?"
};

const PurposIconWrapper = styled.div`
  position: absolute;
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  top: calc(-1rem - 1px);
  left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colours.white};
  user-select: none;
  ${({ annotation }) => css`
    border: solid 1px ${annotationColours[annotation.purpose].border};
    color: ${annotationColours[annotation.purpose].border};
  `}
`;

const AnnotationContentDisplay = styled.div`
  text-indent: 2em;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: ${colours.grey[7]};
`;

export const AnnotationDisplay = ({
  annotation,
  _removeAnnotation,
  updateAnnotation
}) => {
  return (
    <>
      <PurposIconWrapper
        annotation={annotation}
        data-cy="annotation-purpose"
        onClick={() =>
          updateAnnotation({
            ...annotation,
            status: "editing",
            focus: "purpose"
          })
        }
      >
        {iconForType[annotation.purpose]}
      </PurposIconWrapper>
      <AnnotationContentDisplay
        onClick={() =>
          updateAnnotation({ ...annotation, status: "editing", focus: "text" })
        }
      >
        {annotation.text}
      </AnnotationContentDisplay>
    </>
  );
};
