export const FilledChevron = ({ direction }) => {
  return (
    <svg
      height="10px"
      version="1.1"
      viewBox="0.0 0.0 190.1994750656168 189.5249343832021"
      fill="none"
      stroke="none"
      strokeLinecap="square"
      strokeMiterlimit="10"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      transform={direction === "down" ? "scale(1,-1)" : null}
    >
      <clipPath id="p.0">
        <path
          d="m0 0l190.19948 0l0 189.52493l-190.19948 0l0 -189.52493z"
          clipRule="nonzero"
        />
      </clipPath>
      <g clipPath="url(#p.0)">
        <path
          fillOpacity="0.0"
          d="m0 0l190.19948 0l0 189.52493l-190.19948 0z"
          fillRule="evenodd"
        />
        <path
          d="m1.7401575 127.67979l93.35433 -65.85826l93.35433 65.85826z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
