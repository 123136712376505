import { Link } from "../../shared/Link";
import styled from "styled-components";
import { spacing } from "../../../styles/spacing";
import { colours } from "../../../styles/colours";
import { useRouteMatch } from "react-router-dom";

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -${spacing[0.5]};
  flex-grow: 1;
`;

export const PathLink = styled(({ isCurrentPage, ...props }) => <Link {...props} />)`
  ${({ isCurrentPage }) =>
    isCurrentPage ? `color: ${colours.grey[8]};` : `color: ${colours.grey[6]};`}
`;

export const PathDelimeter = styled.span.attrs({ children: ">" })`
  margin-right: ${spacing[0.5]};
  font-size: 0.6rem;
  font-weight: 700;
  color: ${colours.grey[6]};
`;

export const Section = styled.span`
  margin: ${spacing[0.5]};
  margin-bottom: 0;
  margin-right: 0;

  display: flex;
  align-items: center;
`;

const RepoNameSection = styled(Section)`
  margin-left: 0;
`;

export const RepoPathContainer = ({ repository, children }) => {
  const { repo, id } = repository;
  const {
    params: { directoryPath }
  } = useRouteMatch();
  return (
    <Root>
      <RepoNameSection>
        <PathLink to={`/r/${id}`} isCurrentPage={!directoryPath}>
          {repo}
        </PathLink>
      </RepoNameSection>
      {children}
    </Root>
  );
};

export const FullPathLinks = ({ repository, children }) => {
  const {
    params: { directoryPath }
  } = useRouteMatch();
  const pathParts = directoryPath?.split("/");
  return (
    <RepoPathContainer {...{ repository }}>
      {directoryPath &&
        pathParts.map((pathPart, index) => (
          <Section key={index}>
            <PathDelimeter />
            <PathLink
              to={`/r/${repository.id}/files/head/${pathParts
                .slice(0, index + 1)
                .join("/")}`}
              isCurrentPage={index === pathParts.length - 1}
            >
              {pathPart}
            </PathLink>
          </Section>
        ))}
      {children}
    </RepoPathContainer>
  );
};
