const apiFetch = (path, options = {}) =>
  fetch(path, {
    ...options,
    mode: "cors",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(options.headers || {})
    }
  });

class ApiClient {
  login = () =>
    apiFetch("/api/auth/url")
      .then(r => r.text())
      .then(url => (window.location = url));

  loginSuccess = onSuccess =>
    apiFetch("/api/auth/token" + window.location.search)
      .then(r => r.json())
      .then(onSuccess);

  currentUser = onSuccess =>
    apiFetch("/api/auth/current-user")
      .then(r => r.json())
      .then(onSuccess);

  logout = onSuccess =>
    apiFetch("/api/auth/logout")
      .then(r => r.json())
      .then(onSuccess);

  getRepoDirectory = (repoId, directoryPath) =>
    apiFetch(
      `/api/repositories/${repoId}/${encodeURIComponent(directoryPath || "")}`
    ).then(r => r.json());

  getRepoTree = repoId =>
    apiFetch(`/api/repositories/${repoId}/tree`).then(r => r.json());

  getRepoAnnotations = repoId =>
    apiFetch(`/api/repositories/${repoId}/annotations`).then(r => r.json());

  addAnnotation = (annotation, directoryPath, repo_id, file) =>
    apiFetch("/api/annotations", {
      body: JSON.stringify({ annotation, path: directoryPath, repo_id, file }),
      method: "POST"
    });

  patchAnnotation = (annotation, file) =>
    apiFetch("/api/annotations", {
      body: JSON.stringify({ annotation, file }),
      method: "PATCH"
    });

  getUserRepos = () => apiFetch("/api/repositories").then(r => r.json());

  getConcept = id => apiFetch(`/api/concepts/${id}`, {}).then(r => r.json());

  addConcept = ({ title, content }) =>
    apiFetch("/api/concepts", {
      body: JSON.stringify({ title, content }),
      method: "POST"
    }).then(r => r.json());
}

const apiClient = new ApiClient();

export default apiClient;
