import styled from "styled-components";
import apiClient from "../../../../apiClient";
import { MediumPageWidth } from "../../../layout/styles";
import "suneditor/dist/css/suneditor.min.css";
import { PrimaryButton } from "../../../shared/Buttons";
import { useForm } from "react-hook-form";
import { useHistory, useRouteMatch } from "react-router-dom";

const Root = styled(MediumPageWidth)``;
const TitleInput = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  width: 600px;
  max-width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const ContentInput = styled.textarea`
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  height: 200px;
  width: 600px;
  max-width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

export const NewConcept = () => {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const {
    params: { repoId }
  } = useRouteMatch();

  const onSubmit = ({ title, content }) => {
    apiClient
      .addConcept({ title, content, repoId })
      .then(
        response =>
          console.log(response) || history.push(`/r/${repoId}/concepts/${response.id}`)
      );
  };

  return (
    <Root>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitleInput
          {...register("title")}
          placeholder="Title"
          autoComplete="off"
        />
        <div>
          <ContentInput {...register("content")} placeholder="Content" />
        </div>
        <PrimaryButton type="submit">Save</PrimaryButton>
      </form>
    </Root>
  );
};
