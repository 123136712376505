import { Link } from "react-router-dom";
import styled from "styled-components";
import { colours } from "../../../styles/colours";

const ButtonLink = styled(Link)`
  text-decoration: none;
  color: ${colours.grey[6]};
  &:hover {
    color: ${colours.grey[7]};
  }
`;

export const SearchButton = ({ repository }) => (
  <ButtonLink aria-label="file-search" to={`/r/${repository.id}/search`}>
    Search
  </ButtonLink>
);
