import styled, { css } from "styled-components";
import { annotationColours, colours } from "../../../styles/colours";
import { useSelect } from "downshift";
import { FilledChevron } from "../../icons/FilledChevron";
import { forwardRef } from "react";

const Root = styled.div`
  position: absolute;
  border-radius: 1.5rem;
  top: calc(-1.5rem - 1px);
  left: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  ${({ isOpen }) => (isOpen ? "z-index: 2;" : "")}
  ${({ annotation }) => css`
    border: solid 1px ${annotationColours[annotation.purpose].border};
    color: ${annotationColours[annotation.purpose].selectedBorder};
  `}
  cursor: pointer;
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  background-color: ${colours.white};
  padding: 0 0.8rem;
  font-family: inherit;
  font-size: 0.8rem;
  border-radius: 1.5rem;
  box-sizing: border-box;
  outline: none;
  ${({ annotation, isOpen }) => css`
    ${isOpen
      ? css`
          border-radius: 1.5rem 1.5rem 0 0;
          border-bottom: solid 1px
            ${annotationColours[annotation.purpose].background};
          border-top: solid 1px transparent;
        `
      : ""}
    color: ${annotationColours[annotation.purpose].selectedBorder};
    svg {
      fill: ${annotationColours[annotation.purpose].border};
    }
  `}
`;

const OptionsRoot = styled.ul`
  background-color: white;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
  border-radius: 1.5rem;
  overflow: none;
  ${({ annotation }) => css`
    color: ${annotationColours[annotation?.purpose]?.selectedBorder};
  `};
`;
const OptionRoot = styled.li`
  list-style: none;
  outline: none;
  width: 100%;
  padding: 0.8rem;
  font-size: 0.8rem;
  box-sizing: border-box;
  ${({ isLast, isHighlighted, purpose }) => css`
    color: ${annotationColours[purpose]?.border};
    ${isHighlighted
      ? css`
          color: ${annotationColours[purpose]?.selectedBorder};
          background-color: ${annotationColours[purpose]?.background};
        `
      : ""}
    ${isLast
      ? css`
          border-radius: 0 0 1.4rem 1.4rem;
        `
      : ""}
  `}
`;
const ArrowWrapper = styled.div`
  margin-left: 1rem;
`;

const purposeOptions = [
  { id: "info", text: "Info", icon: "i" },
  { id: "warning", text: "Warning", icon: "?" },
  { id: "error", text: "Error", icon: "!" }
];

export const PurposeSelect = forwardRef(
  ({ annotation, updateAnnotation }, ref) => {
    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      getItemProps
    } = useSelect({
      items: purposeOptions,
      defaultSelectedItem:
        purposeOptions.find(p => p.id === annotation.purpose) ||
        purposeOptions[0],
      onSelectedItemChange: ({ selectedItem }) => {
        updateAnnotation({ ...annotation, purpose: selectedItem.id });
      },
      isOpen: annotation.focus === "purpose" || undefined
    });
    return (
      <Root {...{ annotation, isOpen }}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label {...getLabelProps()} style={{ display: "none" }}>
          Select an annotation purpose
        </label>
        <Button
          {...{ annotation, isOpen }}
          type="button"
          {...getToggleButtonProps()}
          data-cy="select-annotation-purpose"
        >
          {selectedItem
            ? `${selectedItem.icon} ${selectedItem.text}`
            : "- Select"}
          <ArrowWrapper>
            <FilledChevron direction={isOpen ? "up" : "down"} />
          </ArrowWrapper>
        </Button>
        <OptionsRoot {...getMenuProps()} {...{ annotation }}>
          {isOpen &&
            purposeOptions.map((purpose, index) => (
              <OptionRoot
                isHighlighted={highlightedIndex === index}
                purpose={purpose.id}
                isLast={index === purposeOptions.length - 1}
                key={`${purpose.id}${index}`}
                {...getItemProps({ item: purpose, index })}
                data-cy="annotation-purpose-option"
              >
                {purpose.icon} {purpose.text}
              </OptionRoot>
            ))}
        </OptionsRoot>
      </Root>
    );
  }
);
