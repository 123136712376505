import { colours } from "../../../styles/colours";
import styled from "styled-components";
import { spacing } from "../../../styles/spacing";
import { FilesList } from "../FilesList";
import { useEffect, useState } from "react";
import apiClient from "../../../apiClient";
import { RepoSearchHeader } from "../repoLayout/RepoSearchHeader";
import { filterTree, mapFilesForSearch } from "./filterTree";
import { useDebounce } from "react-use";
import { ContentCard } from "../styles";
import { SmallPageWidth } from "../../layout/styles";

const Root = styled(SmallPageWidth)``;

const PathSection = styled.div`
  padding-bottom: ${spacing.default};
  padding-left: ${spacing[2]};
  padding-top: ${spacing[1]};
  border-bottom: solid 1px ${colours.grey[1]};
  display: flex;
`;

const Message = styled.div`
  padding: ${spacing[2]};
`;

export const Search = ({ repository, tabs }) => {
  const [allFiles, setAllFiles] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [displayedFiles, setDisplayedFiles] = useState(null);
  useEffect(() => {
    apiClient.getRepoTree(repository.id).then(treeData => {
      if (!treeData.tree) {
        throw new Error("No tree data");
      }
      setAllFiles(mapFilesForSearch(treeData.tree));
    });
  }, [repository.id]);

  useDebounce(
    () => {
      const doSearch = async () => {
        const filteredFiles =
          allFiles && searchTerm && filterTree(allFiles, searchTerm);
        setDisplayedFiles(filteredFiles);
      };
      doSearch();
    },
    100,
    [allFiles, searchTerm]
  );

  return (
    <Root>
      {tabs}
      <ContentCard>
        <PathSection>
          <RepoSearchHeader {...{ repository, setSearchTerm }} />
        </PathSection>
        {!searchTerm && <Message>Enter a file or directory name</Message>}
        {searchTerm && !displayedFiles && <Message>Loading</Message>}
        {searchTerm && allFiles && displayedFiles && !displayedFiles[0] && (
          <Message>No results</Message>
        )}
        {searchTerm && displayedFiles?.[0] && (
          <FilesList files={displayedFiles} />
        )}
      </ContentCard>
    </Root>
  );
};
