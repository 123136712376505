import { useEffect, useRef } from "react";
import styled from "styled-components";
import { colours } from "../../../styles/colours";
import { PathDelimeter, RepoPathContainer, Section } from "./FullPathLinks";

const SearchInput = styled.input`
  font-size: 1.2rem;
  border: none;
  outline: none;
  box-shadow: inset 0px -3px 5px 0px ${colours.grey[3]};
  flex-grow: 1;
  margin-right: 1rem;
  width: 100%;
`;

export const RepoSearchHeader = ({ repository, setSearchTerm }) => {
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <RepoPathContainer {...{ repository }}>
      <Section style={{ flexGrow: 1 }}>
        <PathDelimeter />
        <SearchInput
          name="file-search"
          ref={inputRef}
          onChange={e => setSearchTerm(e.target.value.toLowerCase())}
          autoComplete="off"
        />
      </Section>
    </RepoPathContainer>
  );
};
