import { colours } from "../../styles/colours";
import styled from "styled-components";
import { spacing } from "../../styles/spacing";

export const ContentCard = styled.div`
  background-color: ${colours.white};
  margin: ${spacing[2]} 0;
  border-radius: ${spacing.default};
  box-shadow: 0.1rem 0.1rem 0.2rem ${colours.grey[3]},
    0.2rem 0.2rem 0.2rem ${colours.grey[1]};
`;
