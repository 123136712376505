import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../apiClient";

const AuthCallback = ({ setUser }) => {
  const history = useHistory();
  useEffect(() => {
    apiClient.loginSuccess(({ user }) => {
      setUser(user);
      history.push("/");
    });
  }, [history, setUser]);
  return null;
};

export default AuthCallback;
