import { Route, BrowserRouter } from "react-router-dom";
import DirectoryPage from "./components/repository/DirectoryPage";
import AuthCallback from "./components/AuthCallback";
import { useState } from "react";
import RepositoriesPage from "./components/repositories/RepositoriesPage";

const App = () => {
  const [user, setUser] = useState();
  const isLoggedIn = !!user;
  const isLoadingUser = typeof user === "undefined";
  const isLoggedOut = !user && !isLoadingUser;
  const userProps = { user, setUser, isLoggedIn, isLoggedOut };
  return (
    <BrowserRouter>
      <Route
        path="/"
        exact
        render={() => <RepositoriesPage {...{ userProps }} />}
      />
      <Route
        path="/r/:repoId/:view?/:head?/:directoryPath*"
        exact
        render={() => <DirectoryPage {...{ userProps }} />}
      />
      <Route
        path="/auth-callback"
        exact
        render={() => <AuthCallback setUser={setUser} />}
      />
    </BrowserRouter>
  );
};

export default App;
