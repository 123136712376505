import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

import styled from "styled-components";

export const Link = styled(RouterLink).attrs(({ children }) => ({
  children: children.split("/").map((part, index) => (
    <Fragment key={index}>
      <span style={{ wordBreak: "normal" }}>{part}</span>
      {index !== children.split("/").length - 1 && "/"}
    </Fragment>
  ))
}))`
  color: inherit;
  text-decoration: none;

  word-wrap: break-word;
  word-break: break-all;
  &:hover {
    text-decoration: underline;
  }
`;
