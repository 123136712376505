import styled from "styled-components";
import { colours } from "../../../styles/colours";

export const LineNumber = styled.div`
  text-align: right;
  margin-right: 8px;
  font-size: 1rem;
  line-height: 1.5;
  color: ${colours.grey[4]};
`;
