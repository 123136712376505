import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import apiClient from "../../apiClient";
import { colours } from "../../styles/colours";
import Layout from "../layout/Layout";
import { SmallPageWidth } from "../layout/styles";

const RepoCard = styled(Link)`
  display: block;
  padding: 1rem 2rem;
  margin: 2rem 0;
  background-color: white;
  border-radius: 1rem;
  text-decoration: none;
  box-shadow: 0.1rem 0.1rem 0.2rem ${colours.grey[3]},
    0.2rem 0.2rem 0.2rem ${colours.grey[1]};
  &:hover {
    box-shadow: 0.1rem 0.1rem 0.2rem ${colours.grey[4]},
      0.2rem 0.2rem 0.2rem ${colours.grey[2]};
    background-color: ${colours.primary[1]};
  }
`;

const RepoName = styled.div`
  color: ${colours.grey[8]};

  ${RepoCard}:hover & {
    color: ${colours.grey[9]};
  }
`;

const RepoOwner = styled.div`
  color: ${colours.grey[6]};

  ${RepoCard}:hover & {
    color: ${colours.grey[7]};
  }
`;

const RepositoriesPage = ({ userProps }) => {
  const { user } = userProps;
  const [repositories, setRepositories] = useState();
  useEffect(() => {
    if (user) {
      apiClient.getUserRepos().then(setRepositories);
    }
  }, [user]);
  return (
    <Layout {...userProps}>
      <SmallPageWidth>
        {repositories?.map(repo => (
          <RepoCard key={repo.id} to={`/r/${repo.id}`}>
            <RepoName>{repo.repo}</RepoName>
            <RepoOwner>{repo.owner}</RepoOwner>
          </RepoCard>
        ))}
      </SmallPageWidth>
    </Layout>
  );
};

export default RepositoriesPage;
