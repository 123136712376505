import styled from "styled-components";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import apiClient from "../../../apiClient";
import { useRouteMatch } from "react-router-dom";
import { PurposeSelect } from "./PurposeSelect";
import { PrimaryButton, SecondaryButton } from "../../shared/Buttons";

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  padding: 2rem 1rem 0.5rem 1rem;
  display: flex;
  flex-direction: column;
`;

const ActionsWrapper = styled.div`
  padding: 0.5rem 1.5rem 0.5rem 3rem;
  display: flex;
  justify-content: flex-end;
`;

const TextInput = styled.textarea`
  border: none;
  padding: 0.5rem;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  resize: vertical;
  box-sizing: border-box;
`;

export const AnnotationEdit = ({
  annotation,
  removeAnnotation,
  updateAnnotation,
  file,
  highlightedCode
}) => {
  const textRef = useRef();
  const [originalAnnotation] = useState(annotation);
  const [text, setText] = useState(annotation.text || "");
  const {
    params: { directoryPath, repoId }
  } = useRouteMatch();
  useEffect(() => {
    if (annotation.status === "initial" || annotation.status === "draft") {
      const newStatus = !!text ? "draft" : "initial";
      if (annotation.status !== newStatus) {
        updateAnnotation({ ...annotation, status: newStatus });
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, annotation]);

  useLayoutEffect(() => {
    if (annotation?.focus) {
      if (annotation.focus === "purpose") {
      } else {
        textRef.current.focus();
        textRef.current.setSelectionRange(text.length, text.length);
      }
      updateAnnotation({ ...annotation, focus: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPending = annotation.status === "pending";
  if (isPending) return null;

  const isEditing = annotation.status === "editing";

  return (
    <>
      <PurposeSelect {...{ annotation, updateAnnotation }} />
      <Root>
        <InputWrapper>
          <TextInput
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder="Share your thoughts..."
            ref={textRef}
          />
        </InputWrapper>
        <ActionsWrapper>
          <SecondaryButton
            onClick={() => {
              if (isEditing) {
                updateAnnotation({ ...originalAnnotation, status: "saved" });
              } else {
                removeAnnotation(annotation.id);
              }
            }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              const highlightedChunk = highlightedCode.code
                .split(`\n</div><div class="rouge-line rouge-line-`)
                .slice(annotation.startLine - 1, annotation.endLine)
                .map(
                  (content, index) =>
                    `${
                      index === 0 ? "" : "\n</div>"
                    }<div class="rouge-line rouge-line-` + content
                )
                .join("");
              const newAnnotation = {
                ...annotation,
                status: "saved",
                text,
                highlightedChunk,
                chunkContent: file.content
                  ?.split("\n")
                  .slice(annotation.startLine - 1, annotation.endLine)
                  .join("\n")
              };
              updateAnnotation(newAnnotation);
              if (isEditing) {
                const { status, ...serverFields } = newAnnotation;
                apiClient.patchAnnotation(serverFields, file);
              } else {
                const { status, id, ...serverFields } = newAnnotation;
                apiClient.addAnnotation(
                  serverFields,
                  directoryPath,
                  repoId,
                  file
                );
              }
            }}
          >
            Submit
          </PrimaryButton>
        </ActionsWrapper>
      </Root>
    </>
  );
};
